@import "styles/main/colorPalette.module"
@import "styles/components/common/button"

.dashboard-main-cta
  @apply flex justify-center pointer-events-none
  @apply sticky bottom-0 left-0 w-full
  @screen lg
    @apply relative

  & > .cta
    @extend .button, .button-primary
    @include theme-aware('color', 'text-active')
    @apply text-2xl rounded-full
    @apply pointer-events-auto
    @apply h-12 w-12
    @apply p-0
    @screen lg
      @apply rounded-md
      @apply text-lg
      @apply w-full

    &:hover:not(:active)
      filter: brightness(1.1)
    &:hover:active:not(:disabled)
      @apply transform scale-95
    &:focus
      @apply outline-none

    & > p
      @apply hidden
      @screen lg
        @apply block font-medium
    & > .icon
      @screen lg
        @apply hidden mr-2

    &-primary, &-danger
        @apply border-none
    &-primary
      @include theme-aware('background-color', 'sw-blue')
    &-danger
      @include theme-aware('background-color', 'danger')
