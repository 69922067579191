@import "styles/main/colorPalette.module"
@import "styles/main/globalVars"

.journal-select-cta-container
  @apply flex justify-between
  @apply w-full

  & > .cancel-button
    @apply text-lg

    &:hover
      @apply underline