@import "styles/main/colorPalette.module"
@import "styles/components/common/button"

.page-component-common
  @apply mb-6

  & .component-title
    @include theme-aware("color", "positive-blue")
    @apply text-xl
    @apply mb-2
    @screen lg
      @apply mb-4