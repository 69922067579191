@import "styles/main/colorPalette.module"
@import "styles/main/globalVars"

.add-transaction-mobile-view

  & > .list-slider-container
    @include theme-aware('border-color', 'non-essential', 0.4)
    @apply border-b

    & > .item
      @apply duration-200
      @apply py-3

      &-active
        @include theme-aware('color', 'positive-blue')

  & .slide
    padding: $common-padding