@import "styles/main/colorPalette.module"

.news-summary
  @apply w-full relative

  & > .news-header
    @include theme-aware('color', 'non-essential')
    @apply text-xs font-medium
    & > span
      @apply mr-2

  & > .content
    @include theme-aware('color', 'primary')
    @apply grid gap-x-2
    grid-template-columns: 70% auto
    @screen xl
      grid-template-columns: 80% auto
    @screen 2xl
      grid-template-columns: 80% auto

    & > .col-1
      @apply flex flex-col

      & > a:hover
        @apply underline
      & > .sentiment
        @apply text-sm font-medium
        @apply rounded-md
        @apply relative
        @apply mt-2
        @apply px-2
        width: fit-content

        $bg-opacity: 0.1
        &-neu
          $color-key: 'primary'
          @include theme-aware('color', $color-key)
          @include theme-aware('background-color', $color-key, $bg-opacity)
        &-pos
          $color-key: 'positive-blue'
          @include theme-aware('color', $color-key)
          @include theme-aware('background-color', $color-key, $bg-opacity)
        &-neg
          $color-key: 'danger'
          @include theme-aware('color', $color-key)
          @include theme-aware('background-color', $color-key, $bg-opacity)

    & > .col-2
      @apply flex flex-col items-center p-1

      & > :not(:last-child)
        @apply mb-2
      & > img
        @apply w-full rounded-lg
        max-width: 10rem
      & > button
        @apply flex justify-center items-center
        @apply rounded-full

        & > :first-child:not(:last-child)
          @apply mr-2
        & > .icon
          @apply text-xs

      & > .state-text, & > .add-button
        @include theme-aware('color', 'positive-blue')
      & > .state-text
        @apply text-sm
      & > .remove-button
        @include theme-aware('border-color', 'danger')
        @include theme-aware('color', 'danger')
        @apply w-6 h-6 border flex justify-center items-center

  &-preset-card
    @apply shadow-md px-2 py-2 rounded-lg border
    @include theme-aware('border-color', 'non-essential', 0.05)

  &-preset-border
    @apply py-2 border-b
    @include theme-aware('border-color', 'non-essential', 0.2)