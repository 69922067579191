@import "styles/main/colorPalette.module"

.news-section
  & > .section-header
    @apply flex justify-between mb-3

    & > .title
      & > p
        @include theme-aware('color', 'sw-blue')
        @apply text-xl

    & > button
      @include theme-aware('background-color', 'non-essential', 0.05)
      @apply text-sm font-medium px-2 py-1 rounded-md
      @apply flex justify-center items-center
      & > *:first-child
        @apply mr-2

  & > *:not(.section-header)
    @apply mb-2

  & .shimmer-container
    & > *
      @apply mb-2