@import "styles/components/common/common"
@import "styles/main/colorPalette.module"

.chart-quote-container
  height: fit-content
  & > .quote
    @apply px-4

    & > .symbol
      @apply -mb-2
    & > .name
      @apply mb-2 text-2xl

    & > .stat-container
      @apply flex flex-wrap
      & > *:not(:last-child)
        @apply mr-4

      & > .stat
        @apply flex

        & > *:not(:last-child)
          @apply mr-2

        & > .symbol
          @include theme-aware('color', 'non-essential', 0.7)
          @apply font-medium

        & > .big-price
          @apply text-2xl
        & > .small-percent
          @apply mr-1
