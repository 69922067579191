@import "styles/main/colorPalette.module"

.subscription-table-view
  & > .plans-table-view-section
    @include theme-aware('border-color', 'non-essential')
    @include theme-aware('background-color', 'base-card')
    @apply border rounded-md p-4 flex my-8
    & > .feature-list-col
      flex: 1
    & > .plan-cols
      flex: 2
      @apply flex
      & > .plan-cols-item
        flex: 1
        position: relative
        &.popular
          &::before
            @include theme-aware('background-color', 'base-card')
            @apply absolute z-20
            @apply rounded-md shadow-2xl
            content: ''
            left: -0px
            right: -0px
            bottom: -28px
            top: -28px
          // Popular's border
          &::after
            @include theme-aware('border-color', 'sw-blue')
            @apply absolute z-30 bg-transparent pointer-events-none
            @apply border-2 rounded-lg
            content: ''
            left: -0px
            right: -0px
            bottom: -28px
            top: -28px
          & > .cell
            @apply relative z-20

    & .cell
      @apply flex flex-col justify-center
      @apply relative z-10
      @apply font-medium
      height: 50px
      &.cell-bottom-line
        // Bottom line
        &::before
          @apply absolute inset-0 border-b-2 pointer-events-none
          content: ''
      &.header-cell
        height: 120px
        & > .header-title
          @include theme-aware('color', 'positive-blue')
          @apply font-medium text-2xl uppercase
      &.plan-cell
        @apply items-center
      &.bottom-cell
        @apply text-center
        height: 80px
        & > .choose-plan-btn
          @include theme-aware('border-color', 'positive-blue')
          @include theme-aware('background-color', 'base-card')
          @include theme-aware('color', 'positive-blue')
          @apply border-2 rounded-lg mx-5 duration-200
          @apply font-medium
          outline: none
          height: 60px
          &:hover
            @include theme-aware('background-color', 'positive-blue')
            @include theme-aware('color', 'base-card')
          &.subscribed
            @include theme-aware('background-color', 'positive-blue')
            @include theme-aware('color', 'base-card')
          &:disabled
            @apply cursor-not-allowed

      & > .feature-text
        @apply truncate whitespace-normal
        @apply flex items-center
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical

        & > :first-child
          @apply mr-2
        & > .icon
          @include theme-aware('color', 'positive-blue')

      & > .plan-name
        @include theme-aware('color', 'sw-blue')
        @apply text-2xl font-semibold mb-3
      & > .plan-price
        @apply flex flex-col items-center
        & > .price-text
          @apply text-xl font-semibold
          line-height: 1.4rem
        & > .price-recur
          @apply text-base font-medium
          line-height: 1.4rem