@import "styles/main/colorPalette.module"

.custom-highchart-tooltip
  & .highcharts-tooltip-box
    & > span
      @include theme-aware('background-color', 'background', 1, !important)
      @include theme-aware('color', 'primary', 1, !important)
      @apply font-medium text-lg
      @apply rounded-lg
      @apply p-2 px-4

  & .highcharts-tooltip-header
    top: -10px !important

.stock-chart
  @apply relative

  & > .time-selector-container
    @apply flex justify-between px-2
    @screen lg
      @apply  justify-center mt-4

    & > .time-selector
      @include theme-aware('color', 'primary')
      @apply duration-200 px-2 py-1 text-sm rounded-md
      @screen lg
        @apply mx-4

      &:focus
        @apply outline-none

      &-active
        @include theme-aware('background-color', 'positive-blue')
        @include theme-aware('color', 'base-card')
        @apply font-medium

  & > .overlay
    @include theme-aware('background-color', 'primary', 0.3)
    @include theme-aware('color', 'base-card')
    @apply pointer-events-none
    @apply absolute inset-0
    @apply duration-200
    @apply opacity-0

    &-show
      @apply flex justify-center items-center
      @apply pointer-events-auto
      @apply opacity-100


