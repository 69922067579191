@import "styles/main/colorPalette.module"

.link-brokerage-view
  & > .content
    & > *:not(:last-child)
      @apply mb-2

    & > .brokerage-item
      @apply flex justify-between
      @apply py-2 mb-2
      @apply text-lg

      & > div
        @apply flex items-center
        @apply capitalize
        & > img
          @apply rounded-full
          @apply w-8 h-8
          @apply mr-2

      & > button
        @include theme-aware("color", "primary")
        @apply duration-200
        &:hover, &:focus
          @include theme-aware("color", "danger")
          @apply outline-none