@import "styles/main/colorPalette.module"
@import "styles/components/common/button"
@import "styles/main/globalVars"

.step-container
  // @include theme-aware('color', 'sw-blue')
  & > p
    padding: theme("padding.2") $common-padding

.detail-adding-selection-step
  padding: theme("padding.2") $common-padding
  @screen lg
    padding: $common-padding $common-padding-md

  & > p
    @apply p-0

  & > .general-error
    @include theme-aware("color", "negative-red")
    @apply mb-2

  & > * > .title
    @apply font-medium

  & > .company-summary > ul > li
      @apply flex justify-between

  & > .description-container
    @apply mt-4

    & > .description
      @apply text-sm

  & > .form-container
    @apply relative
    @apply mt-6

    & > *
      @apply mb-4

    & > button
      @extend .button, .button-primary
      @apply sticky bottom-0
      @apply w-full
      @apply mt-6