@import "styles/main/colorPalette.module"
@import "styles/main/globalVars"
@import "styles/animation/basic.sass"

.alert-condition-summary
  @include theme-aware('background-color', 'positive-blue', 0.05)
  padding: $common-padding 0

  & > p
    @apply text-lg
    padding: 0 $common-padding
    @screen lg
      padding: 0 $common-padding-md

  & > .condition-preview-container
    @apply overflow-auto
    @apply flex
    padding: theme("padding.4") $common-padding
    @screen lg
      padding: theme("padding.4") $common-padding-md

    & > *:not(:last-child)
      @apply mr-4

    & > .current-condition-container
      @apply w-full
      @apply flex
      & > *:not(:last-child)
        @apply mr-4
