@import "styles/main/colorPalette.module"
@import "styles/components/common/button"
@import "styles/main/globalVars"

.add-alert-condition-step
  & > .list-slider-container
    @apply border-b

    & > .item
      @apply text-sm font-medium
      @apply duration-200
      @apply py-3
      @screen lg
        @apply text-base

      &-active
        @include theme-aware('color', 'positive-blue')

.need-subscription-step
  & > .title
    @include theme-aware('color', 'positive-blue')
    @apply text-xl text-center font-medium
    @apply mt-4

  & > .caption
    @apply font-medium text-center
    @apply mt-2

.empty-step
  @apply flex justify-items-end items-center
  padding: $common-padding
  @screen lg
    padding: $common-padding $common-padding-md

  & > button
    @extend .button, .button-primary
    @apply w-full
