@import "styles/main/colorPalette.module"

.toastify-container
  @apply p-0 flex rounded-md
.toastify-body
  @apply flex items-center m-0
.Toastify__close-button
  @apply p-2

.toast-template
  @include theme-aware('background-color', 'base-card')
  @include theme-aware('color', 'primary')
  @apply flex items-center w-full

  & > .lottie-container
    @apply w-8 h-8 ml-4 mr-2

  .button
    @include theme-aware('color', 'primary')
    @apply mx-2 border-none bg-transparent
    filter: grayscale(20%)

    &:hover, &:focus
      @apply outline-none
      filter: grayscale(0)

  .message
    @apply break-words p-2
    max-width: 70%

.loading-toast-body .toast-template > .lottie-container
  @apply w-16 h-16 ml-0


.toastify-container
  @include theme-aware('background-color', 'base-card')

.toastify-body
  display: contents

.Toastify__close-button
  &:focus
    @apply outline-none
  & > svg
    @include theme-aware('color', 'primary')


.Toastify__toast
  @include theme-aware('background-color', 'base-card')
  @apply shadow-md