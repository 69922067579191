@import "styles/main/colorPalette.module"
@import "styles/components/common/button"

.password-reset-component
  @apply px-6 mx-auto
  max-width: 450px

  & > p
    @apply text-sm text-center mb-4

  & > .success-noti, & > .error-noti
    @apply mt-1
  & > .success-noti
    @include theme-aware('color', 'success')
  & > .error-noti
    @include theme-aware('color', 'danger')

  & > button
    @extend .button, .button-primary
    @apply w-full mt-8

