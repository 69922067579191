@import "styles/main/colorPalette.module"

.common-condition-card
  @include theme-aware('background-color', 'base-card')
  @apply flex flex-col items-center justify-center
  @apply rounded-md shadow-md
  @apply duration-200
  @apply border-2
  @apply h-24
  @apply px-1
  min-width: 5rem

  & > .icon
    @include theme-aware('color', 'sw-blue')
  & > .name
    @apply text-center text-sm font-semibold
    @apply opacity-75
    @apply mt-2
    filter: brightness(2)

.add-condition-card
  @include theme-aware('border-color', 'sw-blue')
  @extend .common-condition-card
  @apply border-dashed

.condition-preview-card
  @extend .common-condition-card
  @apply relative

  &:only-child
    @apply w-full
  &-active
    @include theme-aware('border-color', 'sw-blue')
  &:hover:not(.condition-preview-card-active)
    @apply transform scale-105

  & > .name:focus
    @apply outline-none
    @apply w-full

  & > button:not(.name)
    @include theme-aware('background-color', 'background')
    @include theme-aware('border-color', 'negative-red')
    @include theme-aware('color', 'negative-red')
    @apply transform -translate-y-1/2 translate-x-1/2
    @apply flex justify-center items-center
    @apply absolute top-0 right-0
    @apply rounded-full
    @apply w-4 h-4
    @apply text-xs
    @apply border