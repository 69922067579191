@import "styles/main/colorPalette.module"

.button
  @apply duration-200 font-medium
  @apply px-12 py-2 rounded-2xl

  &:disabled
    @include theme-aware('background-color', 'disabled', 0.5)
    @include theme-aware('color', 'secondary')
    @apply cursor-not-allowed
    filter: grayscale(100%)
  &:hover:not(:disabled):not(:focus)
    @extend .button-active
  &:hover:active
    @apply transform scale-95
  &:focus
    @apply outline-none

  &-primary
    @include theme-aware('background-color', 'sw-blue')
    @include theme-aware('color', 'text-active')
  &-secondary
    @include theme-aware('border-color', 'positive-blue')
    @include theme-aware('color', 'positive-blue')
    @apply border

    &:hover:not(:disabled):not(:focus)
      @extend .button-primary

  &-round
    @apply rounded-full px-0 py-0 w-10 h-10
  &-active
    filter: brightness(1.2)
