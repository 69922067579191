@import "styles/main/colorPalette.module"

.switch-slider-outer-container
  @apply flex justify-between gap-x-2

.switch-slider-container
  @include theme-aware('background-color', 'non-essential', 0.1)
  @apply rounded-lg w-full
  & > .bar
    @apply h-full rounded-lg

  & > .item
    @include theme-aware('color', 'primary')
    @apply capitalize py-1 font-medium
    &-active
      @include theme-aware('color', 'base-card')