@import "styles/main/colorPalette.module"

.modal
  @apply fixed top-0 left-0 right-0 bottom-0
  @apply z-50

  & > .overlay
    @apply absolute top-0 left-0 right-0 bottom-0
    @apply bg-black bg-opacity-75 z-40

  & > .content
    @include theme-aware('background-color', 'base-card')
    @include theme-aware('color', 'primary')
    @apply transform -translate-x-1/2 -translate-y-1/2
    @apply z-50 absolute rounded-md pointer-events-auto
    @apply flex flex-col
    @apply w-4/5
    min-height: 20rem
    max-height: 90%
    top: 50%
    left: 50%
    @screen lg
      min-width: 40rem
      width: fit-content
      max-width: 800px

    & > .close-button
      @apply absolute right-0 top-0
      @apply w-12 h-12

    & > .title
      @include theme-aware('color', 'positive-blue')
      @apply text-center text-xl font-medium
      @apply py-4
      @apply grid
      grid-template-columns: 20% 60% auto
      @screen lg
        @apply px-12

      & > .left
        @apply flex justify-start
      & > .mid
        @apply flex justify-center
      & > .right
        @apply flex justify-end


    & > .body
      @apply flex-grow flex
      @apply overflow-auto
      @apply relative
      & > *
        @apply w-full

  &-fullscreen > .content
    @apply w-full h-full max-h-screen max-w-screen-2xl
    @apply rounded-none