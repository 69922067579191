@import "styles/main/colorPalette.module"
@import "styles/animation/basic"

.badge
  @apply w-4 h-4 flex justify-center items-center
  @apply text-sm font-medium

  &-round
    @include theme-aware("border-color", "positive-blue")
    @apply border rounded-full

  &-no-border
    @apply border-none

