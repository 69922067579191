@import "styles/main/colorPalette.module"

.form-section-container
  @apply mb-5
  @screen md
    @apply mb-6

  h3
    @include theme-aware('border-color', 'primary', 0.6)
    @include theme-aware('color', 'primary')
    @apply border-b font-medium mb-2 self-start

  & > .form-section
    @apply mb-3
    @screen md
      @apply mb-6

.form-section
  @apply flex flex-col justify-center
  @apply relative

  & > .search-result-container
    @include theme-aware('background-color', 'base-card')
    @apply border border-t-0 rounded-lg rounded-t-none
    @apply w-full -mt-2 pb-2 pt-4
    @apply absolute left-0
    top: 100%

    &-empty
      @apply flex justify-center
    &-static
      @apply static

    & .search-entry
      @apply w-full flex justify-start


.form-inner-container
  @apply relative flex flex-col
  & > .right-cta
    @apply absolute bottom-0
    @apply pb-1
    right: 0


.form-input-container
  @apply relative
  @apply flex flex-col

  &:focus-within, &-active
    .form-label
      @extend .form-label-active


.form-label
  @include theme-aware('color', 'positive-blue')
  @apply absolute origin-left pointer-events-none
  @apply duration-200 ease-in-out
  @apply font-medium flex
  transform: translateY(50%)
  filter: grayscale(0.7)
  bottom: 40%

  @screen md
    @apply text-lg
  @screen lg
    @apply text-base

  &-active
    @include theme-aware('background-color', 'top-card')
    @include theme-aware('color', 'positive-blue')
    @apply transform scale-90 translate-y-1/2
    bottom: 100% !important
    filter: grayscale(0)

  &-inside
    bottom: 50%
    left: 0.75rem


.form-input:not(:placeholder-shown), .form-input-has-placeholder
  filter: grayscale(0)
  & ~ .form-label
    @extend .form-label-active


.form-input
  @include theme-aware('background-color', 'base-card')
  @include theme-aware('border-color', 'positive-blue')
  @include theme-aware('color', 'primary')
  @apply border-b bg-transparent flex items-center
  @apply duration-300 ease-in-out
  @apply pt-3 pb-1
  filter: grayscale(0.7)
  @screen 2xl
    @apply pt-4

  &:focus
    @include theme-aware('border-color', 'positive-blue')
    @apply outline-none
    filter: grayscale(0)
  &-full-border
    @apply p-3 rounded-lg border
    & ~ .form-label
      @extend .form-label-inside
    & ~ .right-cta
      @apply bottom-0
      @apply pb-3
      right: 1rem
  &-no-border
    @apply border-0

  & > .form-icon
    @apply mr-2
  & > .chevron
    @apply duration-200 ml-auto
    &-active
      @apply transform rotate-180
  & > input
    @apply w-full bg-transparent
    &:focus
      @apply outline-none

.date-picker-input
  @apply w-full

.form-dropdown-container
  &:not(.form-dropdown-container-no-border) ~ .form-label
    @extend .form-label-inside

  .form-dropdown
    &__value-container
      @apply py-2

    &__control
      @include theme-aware('background-color', 'base-card')
      @include theme-aware('border-color', 'secondary')

    &__placeholder
      @include theme-aware('color', 'non-essential')

    &__single-value
      @include theme-aware('color', 'primary')

    &__menu
      @include theme-aware('background-color', 'top-card')

    &__option--is-focused
      @include theme-aware('background-color', 'positive-blue', 0.3)

  &-no-border
    & > .form-dropdown__control
      @apply border-t-0 border-l-0 border-r-0 rounded-none
    & > .form-dropdown__control--is-focused
      @include theme-aware('border-color', 'positive-blue')
      @apply shadow-none
    &  .form-dropdown__value-container
      @apply pl-0 pb-0



.form-select-list
  @include theme-aware('background-color', 'base-card')
  @include theme-aware('border-color', 'secondary', 0.3)
  @apply absolute w-full
  @apply py-2 rounded-lg
  @apply shadow-xl
  @apply border
  @apply z-50

  & .option
    @apply w-full text-left font-medium
    @apply px-4 py-2 duration-200

    &-active
      @include theme-aware('background-color', 'primary', 0.2)
      @include theme-aware('color', 'primary')

    &:active, &:hover, &:focus
      @apply outline-none
      &:not(.option-active)
        @include theme-aware('background-color', 'primary', 0.05)
        @include theme-aware('color', 'primary')
        transform: scale(1.01)

    & > :first-child
      @apply mr-4


.form-section-button
  &:focus
    @apply outline-none
    & .icon-container::after
      @apply transform scale-150 opacity-100


.form-choice-content-container
  @apply flex items-center

  & > .icon-container
    @include theme-aware('border-color', 'positive-blue', 0.7)
    @apply border-2 w-6 h-6 rounded-md relative
    @apply flex justify-center items-center
    &-rounded
      @apply rounded-full

    &::before
      @include theme-aware('background-color', 'primary', 0.3)
      @apply duration-200 rounded-full transform scale-0 opacity-0
      @apply absolute top-0 left-0 right-0 bottom-0
      content: ""
    &:hover
      &::before
        @apply transform scale-150 opacity-100
        filter: opacity(0.5)

    & > .icon
      @include theme-aware('color', 'positive-blue')

    & > .hidden-input
      @apply absolute top-0 left-0 right-0 bottom-0
      @apply w-full h-full opacity-0 cursor-pointer

    &-disabled
      @include theme-aware('background-color', 'disabled')
      @include theme-aware('color', 'non-essential')
      @apply cursor-not-allowed
      filter: grayscale(100%)
      & > .icon
        @include theme-aware('color', 'non-essential')

  & .MuiSwitch-thumb
    @include theme-aware('color', 'positive-blue')
  & .MuiSwitch-track
    @include theme-aware('background-color', 'positive-blue', 1, !important)


  & > .form-choice-label
    @apply ml-2
    @screen md
      @apply text-lg

    &-error
      @include theme-aware('color', 'danger')



.form-input-error, .form-error-text, .form-label-error, .form-select-content-error
  @include theme-aware('border-color', 'danger')
  @include theme-aware('color', 'danger')
  @apply font-medium


.form-error-text
  @apply text-sm mt-1

  & > :first-child
    @apply mr-1


.form-option-fade-enter
  opacity: 0
  transform: translateY(-30%)
.form-option-fade-enter-active
  opacity: 1
  transform: translateX(0)
  transition: opacity 300ms, transform 300ms
.form-option-fade-exit
  @apply pointer-events-none
  opacity: 1
.form-option-fade-exit-active
  opacity: 0
  transform: translateY(-30%)
  transition: opacity 300ms, transform 300ms

.form-choice-fade-enter
  opacity: 0
  transform: scale(0)
.form-choice-fade-enter-active
  opacity: 1
  transform: scale(1)
  transition: opacity 200ms, transform 200ms cubic-bezier(0.64, 0.57, 0.67, 1.53)
.form-choice-fade-exit
  opacity: 1
.form-choice-fade-exit-active
  opacity: 0
  transform: scale(0)
  transition: opacity 200ms, transform 200ms cubic-bezier(0.64, 0.57, 0.67, 1.53)