@import "styles/main/colorPalette.module"
@import "styles/components/common/button"
@import "styles/main/globalVars"

.linked-alert-view
  & > .alert-container > .ticker-wrapper
    @apply mb-2
    & > .title
      @apply flex
      & > .ticker
        @apply uppercase ml-2

    & > .alert-wrapper
      @apply w-full
      @apply flex

      & > .alert-summary-card
        @apply w-full

  & > button
    @extend .button, .button-secondary
    @apply flex items-center justify-center
    @apply w-full
    @apply mt-2

    & > *:first-child
      @apply mr-2