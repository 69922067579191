@import "styles/main/colorPalette.module"
@import "styles/components/common/button"

.file-upload-component
  @include theme-aware('border-color', 'non-essential', 0.4)
  @apply border-2 border-dashed
  @apply duration-200
  @apply rounded-md
  @apply p-4
  @screen lg
    @apply border-4

  &:hover
    @apply cursor-pointer
  &:focus
    @apply outline-none
  &:focus, &:hover
    @include theme-aware('border-color', 'positive-blue')

  & > .info
    @apply flex flex-col justify-center items-center

    & > svg
      @apply h-40 w-40

    & > .dialog-opener
      @extend .button, .button-secondary
      @apply rounded-md
      @apply mt-4