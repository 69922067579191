@import "styles/main/colorPalette.module"
@import "styles/pages/common"
@import "./globalVars"

html
  @extend .strech-view

body
  @extend .strech-view
  @include theme-aware('background-color', 'background')
  margin: 0
  font-family: $font-primary, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

#root
  @extend .strech-view
  @include theme-aware('color', 'primary')
  @apply overflow-hidden
  @apply relative