@import "styles/components/common/common"

.company-summary-card
  @apply w-full grid gap-x-4
  grid-template-columns: 7fr 3fr

  & > .info
    @apply flex flex-col items-start
    @apply font-medium
    & > .symbol
      @apply text-sm opacity-75
    & > .company-name
      @apply text-left


  & > .price
    @apply flex flex-col items-end

    & > .change-percent
      @apply text-sm