@import "styles/main/colorPalette.module"
@import "styles/components/common/button"

.transaction-input-component
  & > .type-selector
    @apply mb-4

  & > .submit-button
    @extend .button, .button-secondary
    @apply w-full
    @apply mt-8


  & > * > .total-cost
    filter: grayscale(100%)

  & > .stock-transaction-component
    $section-list: price, quantity, transaction-date, total-cost, ticker, switch-slider-container
    @each $section in $section-list
      & > .#{$section}
        grid-area: $section

    grid-template-areas: "switch-slider-container switch-slider-container" "ticker ticker" "price quantity" "transaction-date transaction-date" "total-cost total-cost"

  & > .option-transaction-component
    $section-list: trade-type, option-type, premium-price, strike-price, contract, transaction-date, expiration-date, total-cost, ticker
    @each $section in $section-list
      & > .#{$section}
        grid-area: $section

    grid-template-areas: "trade-type option-type" "ticker ticker" "premium-price strike-price" "transaction-date transaction-date" "expiration-date expiration-date" "contract total-cost"

  & > .stock-transaction-component, & > .option-transaction-component
    @apply grid gap-4 items-start
    grid-template-columns: repeat(2, minmax(0, 1fr))
    @screen lg
      @apply gap-6
    & > .ticker
      @apply min-w-0
      @apply p-0