@import "styles/main/colorPalette.module"
@import "styles/components/common/button"
@import "styles/main/globalVars"

.alert-manage-view
  max-width: 55rem

  & .subscription-view-container, & .search-company-view-container
    @screen lg
      padding: $common-padding $common-padding-md
