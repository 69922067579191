@import "styles/main/colorPalette.module"
@import "styles/components/common/button"
@import "styles/main/globalVars"


.alert-end-step
  @apply flex flex-col h-full
  & > .illustration
    @include theme-aware('background-color', 'positive-blue', 0.03)
    @apply h-24
    @apply py-2
    @screen lg
      @apply h-32

    & > div
      @apply flex justify-center
    & svg
      @apply w-auto #{!important}

  & > .body
    @apply flex flex-grow flex-col
    padding: $common-padding
    @screen lg
      padding: $common-padding $common-padding-md

    & > .content
      & > .title
        @include theme-aware('color', 'positive-blue')
        @apply text-xl font-medium

      & > ul > li
        @include theme-aware('border-color', 'non-essential', 0.8)
        @apply border-l-2
        @apply text-sm
        @apply mt-1
        @apply pl-2
        @screen lg
          @apply text-base

    & > .cta-container
      @apply flex justify-around
      @apply mt-auto

      & > button
        @extend .button
        @apply px-4 mx-2
        @apply w-40
        @screen lg
          @apply w-full
      & > .another
        @extend .button-secondary
      & > .done
        @extend .button-primary
