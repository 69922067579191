@import "styles/main/colorPalette.module"
@import "styles/components/common/button"
@import "styles/main/globalVars"

.watch-list-add-ticker-button
  @include theme-aware("border-color", "positive-blue")
  @include theme-aware("color", "positive-blue")
  @apply flex items-center justify-center
  @apply border-2 border-dashed
  @apply rounded-md
  @apply w-full
  @apply p-2

  & > :first-child
    @apply mr-2

  &:focus, &:hover
    @apply outline-none
    @apply underline

.watch-list-add-ticker-search-company-view
  padding: $common-padding $common-padding-md

.watch-list-write-ticker-item
  @include theme-aware("border-color", "non-essential", 0.4)
  @include theme-aware("background-color", "base-card")
  @apply rounded-md
  @apply border
  @apply flex
  @apply p-2
  // For beautiful drag-and-drop
  // https://github.com/atlassian/react-beautiful-dnd/issues/485
  @apply top-auto left-auto #{!important}

  & > *
    @apply flex items-center
  & > .move-button
    @apply justify-start
    @apply duration-200
    @apply text-sm
    @apply mr-2
  & > .delete-button
    @include theme-aware("color", "danger")
    @apply justify-end
    @apply text-sm
    @apply ml-auto

  & > .ticker
    @apply justify-start
    @apply truncate
    @apply w-16
  & > .company-name
    @include theme-aware("color", "secondary", 0.7)
    @apply truncate text-sm
    @screen lg
      @apply text-base

  &-is-dragging
    @include theme-aware("border-color", "positive-blue")
    & > .move-button
      @include theme-aware("color", "positive-blue")
      @apply transform rotate-180
      filter: brightness(1.2)