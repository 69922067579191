@import "styles/main/colorPalette.module"
@import "styles/components/common/button"
@import "styles/main/globalVars"
@import "./SettingCommon"

.feedback-and-contact-view
  &-no-padding
    @apply p-0

  & > .form-view
    @screen lg
      @apply flex flex-col
    & > section
      &:not(:last-of-type)
        @screen lg
          @apply mb-3
        @screen 2xl
          @apply mb-6

      & > *
        @apply mb-4

      & > h4
        @apply font-medium

      & > .feedback-form .form-input
        min-height: 15rem
        @screen lg
          min-height: 20rem

      & > .star-container
        @apply mr-2
        & > .star
          @include theme-aware('stroke', 'warning')
          @apply duration-200
          fill: transparent

          &-active
            @include theme-aware('fill', 'warning')

    & > .submit-button
      @extend .button, .button-primary
      @apply sticky bottom-0 duration-200 w-full mb-4
      @screen lg
        @apply w-auto self-center


  & > .thankyou-view
    @apply overflow-x-hidden

    & > div
      padding: theme("padding.4") $common-padding
      & > h4
        @include theme-aware('color', 'positive-blue')
        @apply font-medium text-3xl mb-2

