.link-alert-view
  & > .ticker-wrapper
    @apply mb-2
    & > .title
      @apply flex
      & > .ticker
        @apply uppercase ml-2

    & > .alert-wrapper
      @apply w-full
      @apply flex

      & > .alert-summary-card
        @apply w-full