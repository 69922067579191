@import "styles/main/colorPalette.module"
@import "styles/components/common/button"

.current-plan-section
  @include theme-aware('border-color', 'positive-blue')
  @include theme-aware('background-color', 'base-card')
  @apply border rounded-md shadow-md
  @apply flex justify-between
  @apply p-4 font-medium
  @apply flex-col items-stretch
  @screen md
    @apply flex-row items-center
  @screen lg
    @apply flex-col items-stretch
  @screen xl
    @apply flex-row items-center

  & > .info-container
    @apply flex justify-between items-center
    flex: 5

    & > .plan-title
      @apply text-center flex flex-col
      width: 150px
      & > .plan-title-image
        @apply self-center
        @apply w-16 h-16

      & > .plan-title-name
        @include theme-aware('color', 'positive-blue')
        @apply font-semibold text-3xl mt-2

    & > .plan-price
      @apply text-center
      & > .price-text
        @include theme-aware('color', 'positive-blue')
        @apply font-bold text-2xl
      & > .price-recur
        @apply text-lg

  & > .button-container
    @apply flex
    flex: 4
    @apply mt-6 justify-center
    @screen md
      @apply mt-0 justify-end
    @screen lg
      @apply mt-6 justify-center
    @screen xl
      @apply mt-0 justify-end

    & > .plan-manage
      & > .plan-manage-btn
        @extend .button, .button-secondary
        @apply rounded-md
        @apply border-2
        @apply py-4