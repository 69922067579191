@import "styles/main/colorPalette.module"

.Information-card
  @apply text-sm font-medium shadow-sm
  @apply w-full p-4 rounded-lg

  &:focus
    @apply outline-none

  &-faded
    @include theme-aware('background-color', 'disabled-card')
    @include theme-aware('color', 'primary')
    filter: grayscale(100%)
