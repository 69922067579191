@import "styles/main/colorPalette.module"
@import "styles/components/common/button"
@import "styles/main/globalVars"

.add-alert-summary-step
  padding: $common-padding
  @screen lg
    padding: $common-padding $common-padding-md

  & .error-text
    @include theme-aware("color", "negative-red")
    @apply font-medium

  & > *
    @apply mb-8
    & > .title
      @include theme-aware('color', 'positive-blue')
      @apply font-medium text-lg
    & > .caption
      @apply text-sm

  & > .summary-container
    & > ul
      & > *
        @include theme-aware("border-color", "non-essential", 0.2)
        @apply border-l-2
        @apply px-4 mt-1
        @apply text-sm

  & > .relay-container
    & > .choice-container
      @apply flex justify-between
      @apply mt-4

    & > .relay-error
      @extend .error-text
      @apply text-sm
      @apply mt-2

  & > .cta-container
    @apply flex flex-col mt-12

    & > .submit-error
      @extend .error-text
      @apply mb-4
    & > .submit
      @extend .button, .button-primary
      @apply mb-4
    & > .go-back
      @extend .button, .button-secondary
