@import "styles/main/colorPalette.module"

.transaction-summary
  @include theme-aware('border-color', "non-essential", 0.3)
  @apply border-b
  @apply py-2
  @apply flex

  & > .content
    @apply w-full
    & > .title
      @apply flex justify-between

      & > .left
        @apply flex items-start
        @apply w-full

        & > .icon
          @apply text-lg
          @apply mr-2

        & > .summary
          @apply w-full
          & > .basic
            @apply flex justify-between
            & > .info
              @apply flex items-center
              @apply -mt-1

              & > .trade-type
                @apply capitalize align-top
                @apply mr-2
              & > .ticker
                @apply uppercase

          & > .option
            @apply -mt-1

    & > .info
      @include theme-aware('color', "non-essential")
      @apply flex justify-between
      @apply text-sm

      & > .left
        @apply flex

        & > .logo-container
          @apply mr-2
          @apply w-6

  & > .cta-container
    @apply pl-4
    & > button
      @include theme-aware("color", "positive-blue")
      @apply flex justify-end items-center
      @apply h-full w-full
      @apply duration-200

      &:hover,  &:focus
        @apply transform scale-110
        filter: brightness(1.2)
      &:focus
        @apply outline-none

    & > .form-section
      @apply w-full h-full