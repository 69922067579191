@import "styles/main/colorPalette.module"

.limit-reach-notification
  @apply flex items-center justify-between

  &::before, &::after
    @include theme-aware("background-color", "non-essential", 0.9)
    @apply flex-grow
    height: 1px
    content: ""
  & > p
    @apply mx-4
