@import "styles/main/colorPalette.module"
@import "styles/components/common/button"

.tag
  @include theme-aware('border-color', 'primary')
  @include theme-aware('color', 'non-essential')
  @apply duration-200 flex items-center
  @apply rounded-full border px-3 py-1

  &-active
    @include theme-aware('background-color', 'sw-blue')
    @include theme-aware('border-color', 'sw-blue')
    @include theme-aware('color', 'text-active')
  &:focus:not(.tag-active), &:hover:not(.tag-active)
    @include theme-aware('border-color', 'positive-blue')
    @include theme-aware('color', 'positive-blue')
    filter: brightness(1.2)
  &:focus
    @apply outline-none

  & > .icon
    @apply ml-2 text-xs
