@import "styles/main/colorPalette.module"

.alert-summary-card
  @include theme-aware("border-color", "non-essential", 0.2)
  @apply duration-200
  @apply border-t
  @apply py-2
  filter: grayscale(100%)

  &-active
    filter: grayscale(0)

  .common-preview
    @include theme-aware('color', 'positive-blue')
    @apply text-sm font-medium
    @apply flex items-center

    & > *:not(:last-child)
      @apply mr-2


  & .trigger-title
    @apply flex justify-between

    &-active > .preview > .chevron
      @apply transform rotate-180
      @apply cursor-pointer

    & > .preview
      @extend .common-preview
      & > .chevron
        @apply duration-200

    & > .other
      @apply flex opacity-75

      & > *
        @apply flex items-center

      & > .relay-list
        @apply text-sm
        @apply mr-4
        @screen 2xl
          @apply text-base

        & > *
          @apply ml-2

  & .collapsible-content-container
    @apply px-2 mt-2
    @screen 2xl
      @apply mt-3 px-3

    & .sub-condition-preview
      @include theme-aware("border-color", "non-essential", 0.2)
      @extend .common-preview
      @apply border-l-2
      @apply mb-1 pl-1
      @screen xl
        @apply mb-2 pl-3

.alert-summary-card-cta-container
  @include theme-aware("border-color", "non-essential", 0.2)
  @apply py-2 border

  & .cta
    @include theme-aware('color', 'positive-blue')
    @apply text-right text-sm font-medium
    @apply duration-200 origin-right
    @apply w-full
    @apply px-4 pl-8 py-1
    filter: grayscale(100%)
    @screen 2xl
      @apply text-base

    &-active
      filter: grayscale(0)
    &-delete
      @include theme-aware('color', 'negative-red')
    &:hover
      @apply transform scale-105

