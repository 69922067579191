@import "styles/components/common/button"
@import "styles/main/globalVars"

.subscription-trigger
  @extend .button, .button-secondary
  @apply rounded-md

.subscription-trigger-view-container
  padding: $common-padding
  @screen lg
    min-width: 50rem
