@use "sass:color";
$base-white: #ffffff;
$base-black: #000000;

// Theme-ing
$themes: (
  "light": (
    // * Rating chart
    sell: #ffbd44,
    underperform: #eecd91,
    hold: #bfbfbf,
    outperform: #86aaee,
    buy: #024bd5,
    // *
    text-active: #ffffff,
    // --- Icon
    icon-light: #a0aec0,
    // --- Other
    // disabled: #ebebeb,
    danger: #d6081d,
    success: #238500,
    warning: #ff9900,
    // --- Chart
    chart-line-color: #7080e7,
    chart-upper-gradient: #ffffff7e,
    chart-lower-gradient: #0055ff76,
    // chart-lower-gradient: #ffffff7e,
    // --- Brand
    facebook: #384c9c,
    google: #fefefe,
    apple: #fff,
    // ***
    sw-yellow: #ffbd44,
    sw-blue: #002f87,
    positive-blue: #024bd5,
    negative-red: #d6081d,
    emphasis: $base-black,
    primary: adjust-color($base-black, $lightness: 15%),
    secondary: adjust-color($base-black, $lightness: 30%),
    non-essential: adjust-color($base-black, $lightness: 40%),
    disabled: adjust-color($base-black, $lightness: 50%),
    background: #f2f2f2,
    base-card: #ffffff,
    top-card: #ffffff,
    disabled-card: #f9f9f9,
    shimmer-background: #f2f2f2,
    shimmer-foreground: #f9f9f9,
  ),
  "dark": (
    // * Rating chart
    sell: #ffbd44,
    underperform: #eecd91,
    hold: #bfbfbf,
    outperform: #86aaee,
    buy: #3349ca,
    // *
    text-active: #ffffff,
    // --- Icon
    icon-light: #a0aec0,
    // --- Other
    // disabled: #ebebeb,
    danger: #ff5f6f,
    success: #85ce6b,
    warning: #eb9e64,
    // --- Chart
    chart-line-color: #3349ca,
    chart-upper-gradient: #01093b39,
    chart-lower-gradient: #425af36b,
    // --- Brand
    facebook: #384c9c,
    google: #fefefe,
    apple: #fff,
    // ***
    sw-yellow: #ffbd44,
    sw-blue: #3349ca,
    positive-blue: #7599ff,
    negative-red: #ff5f6f,
    emphasis: $base-white,
    primary: adjust-color($base-white, $lightness: 85%),
    secondary: adjust-color($base-white, $lightness: 70%),
    non-essential: adjust-color($base-white, $lightness: 60%),
    disabled: adjust-color($base-white, $lightness: 50%),
    background: #00011b,
    base-card: #151932,
    top-card: #252a4f,
    disabled-card: #33364e,
    shimmer-background: #2c2f4d,
    shimmer-foreground: #23253c,
  ),
);

//https://blog.prototypr.io/how-i-theme-my-react-app-with-sass-414e8f905541

/**
 * theme-aware - Change color of a css property based on the currently active theme
 *
 * @param  {key}     CSS property
 * @param  {color}   Color name defined in the themes under _color-palette.scss
 *
 * @example - @include theme-aware('background', 'color-background');
 * @returns - background: #FFF;
 */

@mixin theme-aware($key, $color, $opacity: 1, $content: null) {
  @each $theme-name, $theme-color in $themes {
    .#{$theme-name} & {
      #{$key}: rgba(map-get(map-get($themes, $theme-name), $color), $opacity)
        $content;
    }
  }
}

// ! Needs to be .module.scss
// https://github.com/facebook/create-react-app/issues/10047
:export {
  @each $theme, $color-list in $themes {
    @each $key, $value in $color-list {
      #{unquote('#{$key}_#{$theme}')}: $value;
    }
  }
}
