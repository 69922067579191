@import "styles/main/colorPalette.module"
@import "styles/main/globalVars"

.journal-summary-container
  @apply overflow-y-auto
  @apply pt-2
  @apply h-full
  padding: $common-padding

  & .shimmer-container > *
    @apply mb-4

  & .infinite-scroll-component > *
    @apply mb-4