@import "styles/main/colorPalette.module"
@import "styles/animation/basic"

// Mobile spec: https://material.io/components/app-bars-bottom#specs
.navBar
  @include theme-aware('background-color', 'top-card')
  @include theme-aware('border-color', 'primary', 0.1)
  @apply w-full duration-200
  @apply shadow-xl border-t-2
  font-family: var(--primary-font)
  height: 56px
  @screen lg
    @apply flex flex-col border-t-0 border-r
    @apply relative h-screen w-20
    min-width: 5rem // ! Don't remove this, somehow there is a bug!
    max-width: 5rem // ! Don't remove this, somehow there is a bug!

  & .item-container
    @include theme-aware('color', 'positive-blue')
    @apply flex flex-col justify-center items-center
    @apply h-full duration-200
    @screen lg
      @include theme-aware('border-color', 'primary', 0.2)
      @apply h-auto border-b py-4 pt-6 px-1
      &:first-child
        @apply border-t

    & > .icon-container > .icon, & > .text
      filter: grayscale(100%)

    &:focus, &:active, &:hover
      @apply outline-none
      &:not(.item-container-active):not(.logo)
        & > .icon-container > .icon, & > .text
          filter: grayscale(60%)
        & > .icon-container > .icon
          @apply transform scale-110

    &-active
      & > .icon-container > .icon, & > .text
        filter: grayscale(0%)
      @screen lg
        border-right-color: initial !important
      & > .icon-container > .icon
        @apply transform scale-110

    & > .icon-container
      @apply relative
      & > .extra-container
        @apply transform -translate-y-1/2 translate-x-1/2
        @apply absolute right-0 top-0 z-10

      & > .icon
        @apply text-lg duration-200 origin-bottom


    & > .text
      @apply text-xs font-medium duration-100
      @screen lg
        @apply text-sm

  & > *
    @apply w-full

  & > .logo
    @apply border-none p-0 hidden
    filter: grayscale(0)
    @screen lg
      @apply block
    & > img
      @apply duration-200 w-full


  & > .content-outer-container
    @apply flex flex-col
    & > .content, & > .extra
      @apply flex h-full
      @screen lg
        @apply flex-col items-center justify-center h-auto
      & > *
        @apply w-full

    & > .content
      @apply flex-grow
    & > .extra
      @apply mt-auto
      & .item-container
        @apply border-none py-6