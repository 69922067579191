@import "styles/main/colorPalette.module"
@import "styles/components/common/button"
@import "styles/main/globalVars"

.note-section
  & > .note
    @screen lg
      @include theme-aware("background-color", "base-card")
      @apply rounded-md
      @apply shadow-lg
    & > .form-section
      @apply text-xl
      @screen lg
        padding: 0 $common-padding
    & .wysiwyg-inner-container
      min-height: 15rem
      @screen lg
        @apply border-none
      & .ql-toolbar
        @screen lg
          @apply border-none

    & .transaction-viewer-component
      padding: 0 $common-padding
      & > .component-title
        @apply text-base
        @apply mb-1

    & > .form-section
      padding: 0 $common-padding

    & > .new-tag-section
      @apply flex flex-wrap
      @apply my-2
      padding: 0 $common-padding
      & > .tag
        @apply mb-2 mr-2
        @apply text-sm

  & > .save-container
    padding: 0 $common-padding
    @apply w-full
    @apply fixed
    @apply pb-2
    @apply z-10
    bottom: 56px // NavBar height
    @screen lg
      @apply static
      @apply p-0

    & > .save-button
      @extend .button, .button-primary
      @apply w-full
      @screen lg
        @apply mt-4


.note-section-modal-tag-container
  padding: $common-padding
  @screen lg
    padding-left: $common-padding-xl
    padding-right: $common-padding-xl