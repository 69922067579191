@import "styles/main/colorPalette.module"
@import "styles/components/common/button"
@import "styles/main/globalVars"

.brokerage-transaction-component
  & > .brokerage-header
    @apply flex justify-between
    @apply mb-4

    & > .add-more
      @extend .button, .button-secondary
      @apply rounded-md
      @apply w-12
      @apply px-0

      & > p
        @apply hidden
      &:only-child
        @apply flex items-center justify-center
        @apply w-full
        & > p
          @apply block ml-2

    & > .brokerage-selector
      @apply w-full
      &:not(:only-child)
        @apply mr-4

      & .selector-item
        @apply flex items-center
        & > img
          @apply rounded-full
          @apply w-6 h-6
          @apply mr-2

  & > .load-more
    @extend .button, .button-secondary
    @apply w-full rounded-md

.brokerage-transaction-link-brokerage-view
  padding: $common-padding

.subscription-block
  @apply flex flex-col justify-center items-center

  & > *
    @apply mb-2