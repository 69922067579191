@import "styles/components/common/button"
@import "styles/main/globalVars"

.alert-preset-component
  @include theme-aware('color', 'primary')
  @apply flex flex-col
  @apply px-4 pb-4
  @apply w-full
  @screen lg
    padding: $common-padding $common-padding-md


  & > .cta-container
    @apply mt-auto flex flex-col gap-2
    @screen lg
      @apply flex-row justify-end gap-x-4
    & > .confirm-button
      @extend .button, .button-primary
    & > .cancel-button
      @extend .button, .button-secondary