@import "styles/main/colorPalette.module"
@import "styles/components/common/button"

.dropdown-no-form
  @apply relative duration-200

  & > .main-button
    @apply flex items-center justify-center
    @apply duration-200
    &:focus
      @include theme-aware('color', 'positive-blue')
      @apply outline-none

    &-preset-1
      @extend .button, .button-secondary
      @apply px-4 py-1 rounded-md
      filter: grayscale(30%)

      & > *:not(:last-child)
        @apply mr-2

  &-active > .main-button-preset-1
    @extend .button-active

.dropdown-no-form-content
  @include theme-aware('background-color', 'top-card')
  @apply shadow-lg text-right rounded-md
  @apply z-50 absolute right-0

  &-left
    @apply right-auto left-0 text-left