@import "styles/main/colorPalette.module"

.alert-dashboard-view-common
  @apply relative h-full
  @apply overflow-auto
  @screen lg
    @include theme-aware("background-color", "base-card")
    @apply shadow-lg rounded-lg

  & > .dashboard-main-cta
    @apply pb-4

  & > .title
    @include theme-aware("background-color", "base-card")
    @include theme-aware("border-color", "non-essential", 0.2)
    @apply sticky top-0 z-20
    @apply border-b

    & > h3
      @include theme-aware("background-color", "base-card")
      @apply text-lg font-medium
      @apply sticky top-0 z-10
      @apply px-4 py-2
      @screen 2xl
        @apply px-8 py-4