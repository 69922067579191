@import "styles/main/colorPalette.module"
@import "styles/main/globalVars"

// Spec: https://material.io/components/sheets-bottom#specs
.bottom-sheet
  @include theme-aware("background-color", "base-card")
  @apply w-screen
  @apply fixed
  @apply z-50

  & > .main-content
    @include theme-aware("border-color", "non-essential", 0.5)
    @apply flex justify-between items-center
    @apply border-b border-t
    padding-left: $common-padding
    height: 56px
    & > .bottom-sheet-minimize-button
      padding: $common-padding

  & > .supplementary-content
    @apply overflow-auto

.bottom-sheet-overlay
  @apply absolute top-0 bottom-0 right-0 left-0
  @apply bg-black
  @apply z-50