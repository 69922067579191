@import "styles/main/colorPalette.module"
@import "styles/main/globalVars"

.choose-alert-type-step
  @apply px-4 py-6
  @screen lg
    padding: $common-padding $common-padding-md

  & > p
    @apply text-lg font-medium

  & > .type-card-container
    @apply mt-5

    & > *
      @apply mb-3