@import "styles/main/colorPalette.module"

.analyst-rating-chart-with-detail
  & > .info-container
    @apply flex

    & > div > .icon
      @apply mr-1

    & > p
      @include theme-aware("color", "non-essential")