@import "styles/main/colorPalette.module"

.company-alert-summary-card
  @include theme-aware("background-color", "base-card")
  @include theme-aware("color", "primary")
  @apply duration-200 flex flex-col
  @apply w-full p-4
  min-width: 15rem
  filter: grayscale(100%)
  @screen 2xl
    @apply px-8

  &-active
    filter: grayscale(0)
  &-card
    @apply shadow-md rounded-lg
  &-flat
    @include theme-aware("border-color", "non-essential", 0.2)
    @apply border-b


  & > .company-summary
    @apply font-medium

    & > *
      @apply flex justify-between
      & > .name, & > .percent
        @apply text-xs
        @screen 2xl
          @apply text-sm
      & > .price, & > .percent
        @apply text-right
      & > .symbol, & > .price
        @apply text-lg
        @screen 2xl
          @apply text-xl
      & > .symbol
        @include theme-aware("color", "positive-blue")

  & > .content
    @apply flex justify-between
    @apply pt-4
    &:last-child
      @apply mt-auto

    & > .toggle-container, & > .num-alert-container
      @apply flex flex-col justify-end

    & > .num-alert-container
      @apply mt-1 items-start
      & > p
        @apply text-sm font-medium
        @apply flex
        @screen 2xl
          @apply text-base


        & > *
          @apply mr-2

    & > .toggle-container
      @apply items-end

  & > .alert-container
    @apply mt-4

    & > *:not(:last-child)
      @screen xl
        @apply mb-2
      @screen 2xl
        @apply mb-3