@import "styles/main/colorPalette.module"

.list-slider-container
  @apply relative

  & > .slider-bar
    @apply absolute left-0 z-0

  & > .item-wrapper
    @apply duration-200 relative
    &:focus
      @apply outline-none

  &-preset-1
    @include theme-aware('background-color', 'base-card')
    & > .slider-bar
      @include theme-aware('background-color', 'positive-blue')
      @apply h-1 w-full