@import "styles/main/colorPalette.module"

.notification-card
  @include theme-aware("background-color", "base-card")
  @include theme-aware("color", "primary")
  @apply duration-200
  @apply w-full p-4
  @screen 2xl
    @apply px-8

  &-card
    @apply shadow-md rounded-lg
  &-flat
    @include theme-aware("border-color", "non-essential", 0.2)
    @apply border-b

  & > .title
    @include theme-aware("color", "positive-blue")
    @apply flex justify-between
    @apply font-medium

  & > .body
    @apply text-sm mt-2

  & > .date
    @apply flex items-center
    @apply text-xs
    @apply mt-2

    & > .active-icon
      @include theme-aware("color", "negative-red")
      @apply animate-pulse
      @apply mr-2