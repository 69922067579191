@import "styles/main/colorPalette.module"

.alert-type-card
  @include theme-aware('background-color', 'non-essential', 0.1)
  @include theme-aware('border-color', 'non-essential', 0.2)
  @apply shadow-md rounded-md
  @apply flex items-center
  @apply duration-200
  @apply w-full
  @apply border
  @apply px-3
  @apply h-20
  filter: grayscale(100%)
  @screen lg
    @apply px-6

  &-active
    @include theme-aware('background-color', 'base-card')
    filter: grayscale(0)
    &:hover > .content > .title
      @apply underline
    &:focus:not(:active)
      @include theme-aware('border-color', 'non-essential', 0.7)
      @apply outline-none
    &:active
      @apply shadow-none
      outline: none

  & > .content > :not(.description), & > .icon-container
    @include theme-aware('color', 'positive-blue')

  & > .icon-container
    @apply pr-4

  & > .content
    @apply flex flex-col
    @apply relative
    @apply w-full

    & > *
      @apply text-left

    & > .subscription
      @apply text-xs capitalize font-medium
      @apply absolute right-0 top-0
      @apply flex items-center
      & > p
        @apply ml-2


    & > .title
      @apply font-medium
    & > .description
      @apply text-xs
