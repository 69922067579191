@import "styles/main/colorPalette.module"

.stock-change
  @apply duration-200
  &-pos
    @include theme-aware('color', 'success')
  &-neg
    @include theme-aware('color', 'danger')
  &-neu
    @include theme-aware('color', 'secondary')


.red-dot
  @include theme-aware("background-color", "danger")
  @apply w-full h-full
  @apply rounded-full