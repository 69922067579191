@import "styles/main/colorPalette.module"
@import "styles/components/common/button"
@import "styles/main/globalVars"

.search-journal-form
  @apply relative

  &:not(.search-journal-form-dropdown)
    padding: 0 $common-padding

  & > .search-result
    @include theme-aware('background-color', 'base-card')
    @apply flex flex-col
    @apply mt-8

    &-dropdown
      @apply absolute left-0 right-0
      @apply rounded-md shadow-md
      @apply mt-2 p-4
      @apply z-30
      top: 100%

    & > .cta-container
      @apply flex justify-center
      @apply mt-auto
      @apply py-4
      @screen lg
        @apply justify-start

      & > *
        @extend .button
        @apply mr-2
      & > .confirm-cta
        @extend .button-primary
      & > .cancel-cta
        @extend .button-secondary