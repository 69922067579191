$basic-duration: 200ms
$basic-curve: cubic-bezier(0.64, 0.57, 0.67, 1.53)

.basic-fade-with-scale-enter
  opacity: 0
  transform: scale(0)
.basic-fade-with-scale-enter-active
  opacity: 1
  transform: scale(1)
  transition: opacity $basic-duration, transform $basic-duration $basic-curve
.basic-fade-with-scale-exit
  opacity: 1
.basic-fade-with-scale-exit-active
  opacity: 0
  transform: scale(0)
  transition: opacity $basic-duration, transform $basic-duration $basic-curve

.basic-fade-enter
  opacity: 0
.basic-fade-enter-active
  opacity: 1
  transition: opacity $basic-duration, transform $basic-duration $basic-curve
.basic-fade-exit
  opacity: 1
.basic-fade-exit-active
  opacity: 0
  transition: opacity $basic-duration, transform $basic-duration $basic-curve

.basic-fade-with-translate-enter
  opacity: 0
  transform: translateY(-30%)
.basic-fade-with-translate-enter-active
  opacity: 1
  transform: translateX(0)
  transition: opacity $basic-duration, transform $basic-duration
.basic-fade-with-translate-exit
  opacity: 1
.basic-fade-with-translate-exit-active
  opacity: 0
  transform: translateY(-30%)
  transition: opacity $basic-duration, transform $basic-duration


@keyframes basic-fade-in
  0%
    @apply opacity-0
    transform: scale(0)
  100%
    @apply opacity-100
    transform: scale(1)