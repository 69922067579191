@import "styles/main/colorPalette.module"
@import "styles/components/common/button"
@import "styles/main/globalVars"

.add-alert-limit-reach-step
  & > .illustration
    @apply flex justify-center
    @apply h-64
    & > .container
      @apply h-full w-64

  & > .body
    @apply flex flex-col items-center
    padding: $common-padding

    & > h3
      @include theme-aware("color", "positive-blue")
      @apply text-xl font-medium
      @apply mb-4

    & > p
      @apply text-lg text-center

    & > button
      @extend .button, .button-secondary
      @apply mt-8