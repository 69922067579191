@import "styles/main/colorPalette.module"

// Spec: https://material.io/components/app-bars-top#specs
.header
  @include theme-aware('background-color', 'base-card')
  @include theme-aware('border-color', 'non-essential', 0.5)
  @apply w-full py-2 px-4 grid border-b z-40
  @apply sticky top-0
  grid-template-columns: 20% 60% auto
  height: 56px
  @screen lg
    @apply hidden

  & > .left
    @apply flex justify-start items-center
  & > .mid
    @apply flex justify-center items-center
  & > .right
    @apply flex justify-end items-center

  & .header-title-preset
    @include theme-aware('color', 'positive-blue')
    @apply font-medium text-lg text-center
    @apply bg-transparent


