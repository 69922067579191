@import "styles/main/colorPalette.module"

.upload-CSV-to-transaction-component
  @apply relative

  & > .result-container
    & > .file-info
      @include theme-aware("color", "sw-blue")
      @apply flex justify-between
      @apply border-b
      @apply py-2

      & > .basic
        @apply flex items-center
        & > :first-child
          @apply mr-2
        & > p
          @apply truncate
          &::before
            content: " > "


  & > .uploader-container > .brokerage-selector
    @apply mb-4

    & .selector-item
      @apply flex items-center
      & > img
        @apply rounded-full
        @apply w-6 h-6
        @apply mr-2

  & > .overlay
    @apply absolute top-0 bottom-0 right-0 left-0
    @apply flex justify-center items-center
    @apply bg-black bg-opacity-50
    @apply text-2xl text-gray-200
    @apply pointer-events-none
    @apply duration-200
    @apply opacity-0

    &-show
      @apply pointer-events-auto
      @apply opacity-100

.journal-manage-file-upload-retry
  & button
    @include theme-aware("color", "danger")
    @apply flex items-center justify-end
    @apply px-4 py-2
    @apply w-40

    & > *:not(:first-child)
      @apply ml-2