@import "styles/main/colorPalette.module"
@import "styles/main/globalVars"

.setting-common
  @include theme-aware('background-color', 'base-card')
  @apply flex flex-col h-full overflow-auto relative shadow-lg
  padding: theme("padding.4") $common-padding
  @screen lg
    padding: theme("padding.4") $common-padding-xl
    @apply rounded-xl

  & > *
    & > .title
      @include theme-aware('color', 'positive-blue')
      @apply text-xl mb-6
      @screen lg
        @apply text-2xl

    & > section
      @apply mb-5

      & > h3
        @screen lg
          @apply text-xl

    & .join-discord-container
      @include theme-aware('color', 'positive-blue')
      @apply underline
      width: fit-content
      & > p
        @apply mb-2
      & > img
        @screen lg
          @apply w-64