@import "styles/main/colorPalette.module"
@import "styles/main/globalVars"

.condition-type-card
  @include theme-aware("border-color", "non-essential", 0.2)
  @apply flex items-center
  @apply border-b
  @apply w-full
  min-height: 4rem
  padding: theme("padding.2") $common-padding
  @screen lg
    padding: theme("padding.2") $common-padding-md

  &-active
    @include theme-aware("background-color", "positive-blue", 0.1)

  &:hover > .content > .name
    @apply underline
  &:active
    @apply outline-none

  & > :last-child
    @apply ml-auto

  & > .icon
    @include theme-aware('color', 'positive-blue')

  & > .content
    @apply text-left mx-4

    & > .name
      @include theme-aware('color', 'positive-blue')
      @apply font-medium
      @apply duration-200
    & > .description
      @apply text-xs