@import "styles/main/colorPalette.module"
@import "styles/pages/common"
@import "./globalVars"

.__react_component_tooltip.show
  @include theme-aware('background-color', 'primary')
  @include theme-aware('color', 'base-card')
  @apply opacity-100 #{!important}

  &::after
    @include theme-aware('border-top-color', 'primary')

._hidden
  @apply hidden #{!important}

.app
  @extend .strech-view
  @apply w-screen flex flex-col relative
  @screen lg
    @apply flex-row-reverse

  & > .page-content
    @include theme-aware('background-color', 'background')
    @apply flex-grow flex flex-col
    @apply overflow-auto
    @screen lg
      @apply px-4
    @screen xl
      @apply px-8
    & > :not(.header):not(.desktop-header)
      @apply flex-grow
