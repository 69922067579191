@import "styles/components/common/button"
@import "styles/main/colorPalette.module"

.subscription-cards-view
  @apply flex flex-col items-stretch mx-auto
  min-height: 400px
  width: 350px
  max-width: 100%
  @screen sm
    width: 400px
  @screen md
    width: 400px
  @screen xl
    @apply mb-0 border-transparent mx-0
    width: 200px
  @screen 2xl
    width: 260px

  & > .divider-container
    @apply my-6

  & > .plans-section
    @apply flex justify-between self-stretch
    min-height: 400px
    @apply flex-col
    @screen xl
      @apply flex-row

    & > .plan-card
      @include theme-aware('color', 'sw-blue')
      @apply flex flex-col items-stretch text-left rounded-xl
      @apply p-5 border duration-500 border-gray-400 mb-5 mx-auto
      @apply w-full
      &:hover
        @apply border-gray-400
      &.popular
        @include theme-aware('background-color', 'sw-blue')
        @include theme-aware('color', 'base-card')
        & > .plan-card-price
          @include theme-aware('color', 'base-card')
        & > .plan-card-subscribe-btn
          @include theme-aware('color', 'sw-blue')

      & > .plan-card-name
        @apply w-20 text-2xl font-semibold

      & > .plan-card-price
        @apply text-xl text-3xl mb-2
        & > .price-text
          @apply font-semibold
        & > sub
          @apply font-normal text-base

      & > .plan-card-description
        @apply leading-tight mb-2
        @screen xl
          @apply text-sm
        @screen 2xl
          @apply text-base

      & > .plan-card-features
        @apply mb-8
        & > .feature-item
          @apply flex items-start mt-3

          & > .feature-item-icon
            @apply mt-1 mr-2
            @include theme-aware('color', 'icon-light')

          & > .feature-item-text
            @apply font-medium
            @screen xl
              @apply text-sm
            @screen 2xl
              @apply text-base

      & > .plan-card-subscribe-btn
        @apply duration-200 font-medium px-12 py-4 rounded-md
        @apply mt-auto border-2 bg-white font-semibold
        @include theme-aware('border-color', 'positive-blue')
        outline: none
        &.subscribed
          @apply cursor-not-allowed
          @include theme-aware('background-color', 'positive-blue')
          @include theme-aware('color', 'base-card')
        &:hover
          @include theme-aware('background-color', 'positive-blue')
          @include theme-aware('color', 'base-card')
        &:disabled
          @apply cursor-not-allowed

        @screen xl
          @apply px-8 py-2
        @screen 2xl
          @apply px-12 py-4
