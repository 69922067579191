@import "styles/main/colorPalette.module"
@import "styles/components/common/button"
@import "styles/pages/common"
@import "./SettingCommon"

.account-view
  & > section
    & > *
      @apply mb-4

    & > .address-group
      @apply grid grid-cols-2 gap-y-4 gap-x-4 mt-6

      & > .street
        grid-column: 1 / -1

    & > .forgot-password
      @include theme-aware('color', 'positive-blue')
      @apply underline font-medium

  & > .save-button
    @extend .button, .button-primary
    @apply sticky bottom-0 duration-200
    @apply w-full

  & > .edit-cta
    @include theme-aware('color', 'primary')
    @apply absolute
    top: 1rem
    right: 1rem