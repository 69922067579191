@import "styles/main/colorPalette.module"

.journal-summary
  & > .cta-container
    @apply h-full flex
    & > *
      @apply flex flex-col justify-center items-center
      @apply text-sm px-4 duration-200
      &:focus
        @apply outline-none
      &:last-child
        @apply rounded-r-lg
      &:first-child
        @apply rounded-l-lg
    & > .delete-cta
      @include theme-aware('background-color', 'non-essential')
      @include theme-aware('color', 'base-card')

  & > .outer-container
    @include theme-aware('background-color', 'top-card')
    @include theme-aware('border-color', 'non-essential', 0.3)
    @include theme-aware('color', 'primary')
    @apply border-r-8 rounded-md duration-200 shadow-md
    @apply relative cursor-pointer
    @apply pl-4 py-2 grid
    grid-template-columns: 90% auto
    min-height: 10rem
    @screen 2xl
      min-height: 12rem

    &-active
      @include theme-aware('border-right-color', 'positive-blue', 0.8)


    & > .icon
      @apply flex justify-center items-center
      @apply w-full h-full

    & > .inner-container
      @apply w-full flex flex-col

      & > .content-container
        @apply mb-4
        & > .header
          @apply capitalize text-sm
          // ? Should we do this for dragging purposes?
          @apply select-none

        & > .title
          @apply truncate text-left font-medium text-lg
          // ? Should we do this for dragging purposes?
          @apply select-none
          @apply w-full

        & > .note
          // ? Should we do this for dragging purposes?
          @apply select-none mt-2
          &:not(:last-child)
            @apply mb-3
          & > p
            @apply truncate text-sm


      & > .tag-container
        @apply mt-auto mb-2

        & > .tag
          @apply text-sm
