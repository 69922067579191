@import "styles/main/colorPalette.module"

.watch-list-ticker-item
  @include theme-aware("border-color", "non-essential", 0.3)
  @apply border-t
  @apply w-full
  @apply grid
  @apply py-2
  grid-template-columns: 30% auto 30%

  & > .info
    &:hover > .ticker
      @apply underline
    & > .name
      @include theme-aware("color", "secondary", 0.8)
      @apply truncate text-sm

  & > .sparkline-container
    @apply flex items-center justify-center
    @apply w-full
    & > svg
      max-width: 10rem

  & > .quote
    @apply flex flex-col justify-end
    @apply text-right

    & > .percent
      @apply text-sm