@import "styles/main/colorPalette.module"

.watch-list
  @include theme-aware("background-color", "top-card")
  @apply rounded-md
  @apply p-2 px-4
  @apply shadow-md

.watch-list-title
  @apply flex items-center

  &:focus, &:hover
    @apply outline-none
    & > .content > .title
      @apply underline

  & > .icon-container
    @include theme-aware("border-color", "positive-blue")
    @apply flex justify-center items-center
    @apply rounded-full
    @apply border-2
    @apply h-8 w-8
    @apply mr-4

  & > .content
    @apply flex flex-col items-start justify-center

    & > .title
      @include theme-aware("color", "secondary")
      @apply text-lg
    & > .num-item
      @include theme-aware("color", "non-essential")
      @apply text-sm
      @apply -mt-1

.watch-list-trigger
  @apply flex justify-between items-center
  @apply duration-200
  @apply w-full

  &:focus
    @apply outline-none
    & > .watch-list-title > .content > .title
      @apply underline

  & > .cta
    @apply flex items-center
    & > .chevron
      @apply duration-200
      @apply ml-4
      &:focus
        @apply outline-none
        filter: brightness(1.2)

  &-active
    @apply mb-2
    & > .cta > .chevron
      @apply transform rotate-180
      @apply duration-200


.watchlist-dropdown-container
  @apply py-2
  & button
    @apply flex items-center justify-end
    @apply duration-200
    @apply w-full
    @apply p-4
    min-width: 10rem
    filter: grayscale(100%)

    & > p
      @apply mr-2
    &:focus, &:active, &:hover
      filter: grayscale(0)

  & .delete-cta
    @include theme-aware("color", "danger")
  & .edit-cta
    @include theme-aware("color", "positive-blue")


.new-watch-list-button
  @include theme-aware("border-color", "positive-blue")
  @apply border-2 border-dashed
  @apply rounded-md
  @apply p-2 px-4