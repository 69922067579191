@import "styles/main/colorPalette.module"

.wysiwyg-input
  @apply relative
  & .wysiwyg-inner-container
    @apply border border-b-0 rounded-t-md
    @apply  mb-12

    & > *:not(.ql-toolbar)
      @include theme-aware('border-color', 'non-essential', 0.1, !important)
      @apply border-none

    & .ql-toolbar
      @apply flex w-full absolute left-0 -mt-12
      @apply rounded-b-md border border-t-0
      top: 100%

    & > .ql-container
      @apply rounded-t-md border-b-0
      & > .ql-editor
        @apply w-full
        min-height: 10rem
        @screen lg
          min-height: 20rem
      & > .ql-blank::before
        @include theme-aware('color', 'secondary')

  .extra-section-container
    @apply py-2


.simpleWysiwyg
  & .ql-toolbar
    & > .ql-formats:first-child
      @apply mr-auto
    & > .ql-formats:not(:first-child)
      @apply mr-0 ml-2
