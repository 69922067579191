@import "styles/main/colorPalette.module"
@import "styles/main/globalVars"

.alert-and-notification-view
  & .common-button
    @include theme-aware('background-color', 'non-essential', 0.1)
    @apply px-2 text-sm rounded-md
  & .title-text
    @include theme-aware('color', 'positive-blue')
    @apply text-lg font-medium

  & > *
    & > *
      padding: $common-padding
    & > .title
      @apply flex justify-between

      & > h3
        @extend .title-text

      & > button
        @extend .common-button

  & > .alert-section
    & > .alert-summary-container
      padding: $common-padding
      @apply overflow-auto
      @apply pt-0 pb-8
      @apply flex
      @apply h-48

      & > *
        @apply mr-4

        & > *
          @apply h-full

      & .add-alert-button
        @include theme-aware('background-color', 'base-card')
        @include theme-aware('border-color', 'positive-blue')
        @include theme-aware('color', 'positive-blue')
        @apply flex items-center justify-center
        @apply border-2 border-dashed
        @apply rounded-lg
        @apply shadow-lg
        @apply text-lg
        min-width: 10rem
        min-height: 10rem

        & > p
          @apply mr-2 font-medium

  & > .notification-section
    & > .notification-container > *
      @apply mb-4