@import "styles/main/colorPalette.module"
@import "styles/components/common/button"
@import "styles/main/globalVars"

.news-filter-view
  @apply flex flex-col
  @apply overflow-auto
  @apply h-full
  @screen lg
    max-height: 40rem

  & > .section-container
    @apply overflow-auto
    padding: $common-padding
    @screen lg
      padding: 0 $common-padding-md

    & > .news-filter-section
      @apply py-2
      &:not(:last-child)
        @include theme-aware("border-color", "non-essential", 0.3)
        @apply border-b
        @apply mb-8

      & > .title-container
        @apply flex justify-between
        & > .filter-title
          @apply text-lg
          @screen lg
            @apply text-xl

        & > .cta-container > *
          @include theme-aware("color", "positive-blue")
          @apply flex items-center
          & > *
            @apply ml-2

      & > .filter-content
        @apply flex flex-wrap
        @apply mt-3

        & > *
          @apply mr-4 mb-2

      & > .source-content
        @apply overflow-auto
        & > *
          @apply mr-2 mb-2
          @screen lg
            @apply mb-4

  & > .cta-container
    @include theme-aware("border-color", "non-essential", 0.3)
    @apply flex justify-center
    @apply border-t
    @apply mt-auto
    @apply py-2
    @screen lg
      @apply justify-end
      padding: $common-padding $common-padding-md

    & > .cancel
      @include theme-aware("color", "positive-blue")
      &:hover
        @apply underline
    & > .apply
      @extend .button-primary
    & > *
      @extend .button
      @apply rounded-md
      @apply mx-2
