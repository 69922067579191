@import "styles/main/colorPalette.module"
@import "ViewCommon"

.all-alert-view
  @extend .alert-dashboard-view-common
  @apply relative

  & > .title
    @apply flex justify-between

    & > .list-slider-container
      @apply w-40
      @apply py-2
      @screen 2xl
        @apply py-4

      & .item
        @apply capitalize font-medium text-sm
        &:not(:last-child)
          @include theme-aware("border-color", "non-essential", 0.2)
          @apply border-r

  & > .alert-container > .shimmer-container
    @apply px-4 py-2
    & > *
      @apply mb-2

  & > .dashboard-main-cta
    @apply pb-4 mt-4

