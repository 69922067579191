@import "styles/main/colorPalette.module"

.common-page
  @include theme-aware('background-color', 'background')
  @apply relative

  & > *
    @apply h-full

.strech-view
  @apply min-h-screen h-screen
  min-height: -moz-available
  min-height: -webkit-fill-available
  min-height: stretch
  height: -moz-available
  height: -webkit-fill-available
  height: stretch

// * Tablet
.tablet-4-8-grid
  @screen lg
    @apply grid gap-x-8
    min-height: 0
    grid-template-columns: minmax(0, 4fr) minmax(0, 8fr)
    grid-template-areas: "content-1 main-content"
.tablet-4-5-3-grid
  @screen lg
    @apply grid gap-x-8
    min-height: 0
    grid-template-columns: minmax(0, 4fr) minmax(0, 5fr) minmax(0, 3fr)
    grid-template-areas: "content-1 main-content content-2"
.tablet-3-9-grid
  @screen lg
    @apply grid gap-x-8
    min-height: 0
    grid-template-columns: minmax(0, 3fr) minmax(0, 9fr)
    grid-template-areas: "content-1 main-content"

.tablet-3-5-4-grid
  @screen lg
    @apply grid gap-x-8
    min-height: 0
    grid-template-columns: minmax(0, 3fr) minmax(0, 5fr) minmax(0, 4fr)
    grid-template-areas: "content-1 main-content content-2"

.tablet-2-10-grid
  @screen lg
    @apply grid gap-x-8
    min-height: 0
    grid-template-columns: minmax(0, 2fr) minmax(0, 10fr)
    grid-template-areas: "content-1 main-content"


// * Desktop
.desktop-3-5-4-grid
  @screen 2xl
    @apply grid gap-x-8
    min-height: 0
    grid-template-columns: minmax(0, 3fr) minmax(0, 5fr) minmax(0, 4fr)
    grid-template-areas: "content-1 main-content content-2"

.desktop-2-10-grid
  @screen 2xl
    @apply grid gap-x-8
    min-height: 0
    grid-template-columns: minmax(0, 2fr) minmax(0, 10fr)
    grid-template-areas: "content-1 main-content"

.desktop-3-9-grid
  @screen 2xl
    @apply grid gap-x-8
    min-height: 0
    grid-template-columns: minmax(0, 3fr) minmax(0, 9fr)
    grid-template-areas: "content-1 main-content"


// * Common
.desktop-3-5-4-grid, .tablet-3-9-grid, .tablet-4-8-grid, .tablet-4-5-3-grid, .tablet-2-10-grid, .tablet-3-5-4-grid

  & > .main-content
    grid-area: main-content
  & > .content-1
    grid-area: content-1
  & > .content-2
    grid-area: content-2