@import "styles/main/colorPalette.module"
@import "styles/main/globalVars"

.search-company-view
  @apply relative
  padding: $common-padding $common-padding
  @screen lg
    @apply p-0
    min-width: 20rem
  & > .search-form-with-label
    @apply mt-4

  & > .search-result
    @include theme-aware('background-color', 'base-card')
    @apply mt-8

    &-dropdown
      @apply rounded-md shadow-md
      @apply absolute left-0
      @apply mt-2 p-4
      @apply w-full
      @apply z-30
      top: 100%

    & > *
      @include theme-aware('border-color', 'primary', 0.1)
      @apply mb-4 border-b pb-2

      &:hover
        & > .info > .company-name
          @apply underline