@import "styles/main/colorPalette.module"
@import "styles/pages/common"


.emoji-picker-container
  @include theme-aware("border-color", "non-essential", 0.3)
  @include theme-aware("background-color", "base-card")
  @apply border rounded-md
  @apply duration-200
  @apply w-12 h-12

  &:focus-within, &:hover
    @include theme-aware("border-color", "positive-blue")
    & > button
      @apply outline-none
  &-active
    @include theme-aware("border-color", "positive-blue")
  & > button
    @apply w-full h-full

.emoji-picker-inner-container
  & > p
    @include theme-aware("color", "secondary")
    @apply text-center text-lg
    @apply py-2

  & > .emoji-picker-react
    @include theme-aware("border-color", "non-essential", 0.3)
    @include theme-aware("background-color", "base-card")
    @apply rounded-none
    @apply border-none
    @apply shadow-md
    @apply w-full
    min-height: 25rem
    @screen lg
      @apply rounded-md
      @apply border
      min-height: 0rem
      min-width: 30rem


    & > .emoji-categories
      // ! Hidden until we find a way to adopt to dark mode
      @apply hidden

    & > .active-category-indicator-wrapper + div
      @apply py-4
      & > .emoji-search
        @include theme-aware("border-color", "non-essential", 0.3)
        @include theme-aware("background-color", "top-card")
        @apply py-2
      & > .skin-tones-list
        top: 50%

    & .emoji-group
      &::before
        @include theme-aware("background-color", "base-card")
        @include theme-aware("color", "secondary")
        @apply text-xs

