@import "styles/main/colorPalette.module"
@import "styles/components/common/button"

.journal-manage-mobile-CTA
  @include theme-aware('border-color', 'non-essential', 0.7)
  @include theme-aware('background-color', 'base-card')
  @apply flex items-center
  @apply overflow-auto
  @apply border-b
  @apply sticky
  @apply py-2
  @apply z-10
  top: 56px // Header height

  & > button
    @extend .button, .button-secondary
    @apply flex items-center justify-center
    @apply rounded-md
    @apply px-4
    @apply ml-3
    @apply h-16
    min-width: 12rem
    & > :first-child
      @apply mr-2