@import "styles/main/colorPalette.module"
@import "styles/pages/common"
@import "styles/main/globalVars"

.desktop-header
  @apply hidden
  @screen lg
    @include theme-aware('background-color', 'background')
    @apply flex items-center
    min-height: 5rem

    & > li
      @apply text-2xl
      @apply flex
      & > .bread
        @apply capitalize

        &:hover
          @apply underline


      &:not(:last-child)::after
        @apply mx-4
        content: ">"