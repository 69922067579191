@import "styles/main/colorPalette.module"
@import "styles/components/common/button"

.setting-main-view
  @include theme-aware('background-color', 'base-card')
  @apply flex flex-col h-full shadow-lg
  @screen lg
    @apply rounded-xl

  & > .cta
    @include theme-aware('border-color', 'primary', 0.2)
    @include theme-aware('color', 'positive-blue')
    @apply flex items-center p-4 duration-200
    @apply border-b
    @screen lg
      @apply border-0 px-8
      &:first-child
        @apply rounded-t-xl
      &:last-child
        @apply rounded-b-xl

    &-active
      @include theme-aware('background-color', 'sw-blue')
      @include theme-aware('color', 'text-active')
      & > :last-child
        filter: grayscale(0%)

    &:hover:not(:focus)
      @include theme-aware('background-color', 'sw-blue', 0.8)
      @include theme-aware('color', 'text-active')

    &:focus
      @apply outline-none

    & > :first-child
      @apply mr-4
    & > :last-child
      filter: grayscale(100%)

  & > .logo
    @apply mt-auto mb-2 w-40 self-center

